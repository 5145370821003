const convertDateFormat = (date: string): string => {
  const d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hours = d.getHours(),
    minutes = d.getMinutes(),
    seconds = d.getSeconds()
  const res = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
  return res
}

export default convertDateFormat
