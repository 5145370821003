<template>
  <div class="vModalUnlinkingAccount">
    <div
      class="vModalUnlinkingAccount__overlay"
      @click="closeUntyingModal"
    />
    <div class="vModalUnlinkingAccount__content">
      <div class="vModalUnlinkingAccount__header">
        <div class="vModalUnlinkingAccount__title">
          Предупреждение
        </div>
        <div class="vModalUnlinkingAccount__close">
          <vCloseButton @handleClick="closeUntyingModal" />
        </div>
      </div>
      <div class="vModalUnlinkingAccount__body">
        Вы действительно хотите отвязать учётную запись Telegram ?
      </div>
      <div class="vModalUnlinkingAccount__footer">
        <vButton @click="untyingTelegram">
          Отвязать
        </vButton>
        <vButton
          theme="gray"
          @click="closeUntyingModal"
        >
          Назад
        </vButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vCloseButton from '@/components/v-close-button'
import vButton from '@/components/v-button'
export default {
  name: 'VModalUnlinkingAccount',
  components: {
    vCloseButton,
    vButton,
  },
  emits: ['closeUntyingModal', 'untyingTelegram'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters('user', ['USER_DATA']),
  },
  async mounted() {},
  methods: {
    closeUntyingModal() {
      this.$emit('closeUntyingModal')
    },

    untyingTelegram() {
      this.$emit('untyingTelegram')
      this.closeUntyingModal()
    },
  },
}
</script>

<style lang="scss">
.vModalUnlinkingAccount {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dcdee3;
    opacity: 0.9;
    z-index: 1;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 666px;
    min-height: 215px;
    background-color: #ffffff;
    border-radius: 12px;
    font-family: 'Commissioner';
    color: #010d35;
    text-align: center;
    padding: 20px 16px 0 16px;
    @media (max-width: 769px) {
      width: calc(100% - 32px);
    }
  }
  &__title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
  &__close {
    .vCloseButton {
      background: #f7f8fa !important;
      border: 1px solid #edeef1 !important;
      &:hover {
        background: #ec4e4e !important;
      }
    }
  }
  p {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
  }
  &__body {
    font-size: 17px;
    text-align: center;
    color: #010d35;
    padding: 0 20px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    button {
      width: 313px !important;
      height: 54px !important;
    }
    .cancel {
      background: #edeef1 !important;
      color: #343d5d !important;
    }
  }
}
@media screen and (max-width: 770px) {
  .vModalUnlinkingAccount {
    &__content {
      width: 343px;
      padding: 20px 16px 0 16px;
      justify-content: start;
    }
    &__body {
      padding: 0;
    }
    &__footer {
      flex-direction: column;
      button {
        width: 319px !important;
        height: 54px !important;
      }
      .blue {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
