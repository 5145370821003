import { defineStore } from 'pinia'
import { ref } from 'vue'
import WaiterService from '@/services/waiterService'

export const useSbpStore = defineStore('sbpStore', () => {
  const sbpData = ref ({
    success: false,
    result: {
      pam: ''
    }
  })

  const getSbpInfo = async () => {
    const sbpDataResponse = await WaiterService.getInfoSbp()
    sbpData.value.success = Array.isArray(sbpDataResponse.result) ? false : true
    sbpData.value.result = Array.isArray(sbpDataResponse.result) ? {} : sbpDataResponse.result
  }

  const saveSbpInfo = async (phone, bankId) => {
    const sbpDataResponse = await WaiterService.saveInfoSbp(phone, bankId)
    sbpData.value.success = sbpDataResponse.success
    sbpData.value.result.pam = sbpDataResponse.pam
  }

  const verifySbpInfo = async (status) => {
    await WaiterService.verifySbp(status)
    sbpData.value.result.verification_pam = status
  }

  return {
    sbpData,
    getSbpInfo,
    saveSbpInfo,
    verifySbpInfo
  }
})
