<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      width="20"
      height="20"
      rx="10"
      fill="#419FD9"
    />
    <rect
      x="0.5"
      width="20"
      height="20"
      rx="10"
      fill="url(#paint0_linear_12259_1364)"
    />
    <path
      d="M4.99474 9.77948C7.90278 8.48586 9.84193 7.63303 10.8122 7.22098C13.5825 6.0445 14.1581 5.84014 14.5333 5.83332C14.6158 5.83191 14.8003 5.85279 14.9199 5.9518C15.0208 6.03541 15.0485 6.14836 15.0618 6.22763C15.0751 6.3069 15.0917 6.48748 15.0785 6.62858C14.9284 8.23909 14.2788 12.1474 13.9483 13.9512C13.8085 14.7144 13.5332 14.9703 13.2666 14.9954C12.6873 15.0498 12.2474 14.6045 11.6864 14.229C10.8084 13.6414 10.3124 13.2756 9.46018 12.7022C8.47529 12.0395 9.11375 11.6753 9.67503 11.08C9.82193 10.9243 12.3743 8.55389 12.4237 8.33886C12.4299 8.31197 12.4356 8.21172 12.3773 8.15879C12.319 8.10586 12.2329 8.12396 12.1708 8.13836C12.0827 8.15876 10.6801 9.10529 7.96305 10.9779C7.56494 11.2571 7.20434 11.3931 6.88125 11.3859C6.52508 11.3781 5.83994 11.1803 5.33061 11.0113C4.7059 10.8039 4.20939 10.6943 4.25262 10.3422C4.27514 10.1588 4.52251 9.97121 4.99474 9.77948Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12259_1364"
        x1="10.5"
        y1="0"
        x2="10.5"
        y2="19.8516"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2AABEE" />
        <stop
          offset="1"
          stop-color="#229ED9"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
