import { $host } from './api'
import { AxiosResponse } from 'axios'
import { IBanksResponse } from '@/types/bank'

class BankService {
  async fetch() {
    const { data }: AxiosResponse<IBanksResponse> = await $host.get('/bank/list?limit=200')
    return data
  }
}

export default new BankService()
