<template>
  <div class="vModalPolitics">
    <div class="vModalPolitics__overlay" />
    <div class="vModalPolitics__content">
      <p class="vModalPolitics__title">
        Для продолжения, Вам необходимо согласиться с&nbsp;условиями политики
        конфиденциальности и&nbsp;обработки данных
      </p>

      <div class="vModalPolitics__politicsContainer">
        <div class="vModalPolitics__politics">
          {{ legalDocument.document }}
          <!-- <p class="vModalPolitics__politics-block">
            Пожалуйста, внимательно прочитайте положения этой Политики Конфиденциальности и&nbsp;обработки персональных данных и&nbsp;свяжитесь с&nbsp;нами по&nbsp;электронной почте, указанной в&nbsp;этом документе, если у&nbsp;вас возникнут вопросы. Используя Сайты и\или Облачный сервис Tapper, получая услуги на&nbsp;Сайте и/или посредством использования Облачного сервиса Tapper и/или проставляя соответствующую отметку (например, нажимая на&nbsp;&laquo;согласен&raquo;) вы&nbsp;соглашаетесь со&nbsp;всеми условиями настоящей Политики конфиденциальности и&nbsp;обработки персональных данных, подтверждаете свое ознакомление с&nbsp;изложенными здесь условиями и&nbsp;даете нам свое свободное и&nbsp;однозначное согласие использовать Ваши Персональные данные в&nbsp;соответствии с&nbsp;условиями настоящей Политики конфиденциальности и&nbsp;обработки персональных данных.
          </p>

          <p class="vModalPolitics__politics-block">
            <b>Термины и определения</b>
          </p>

          <p class="vModalPolitics__politics-paragraph">
            <b>Оператор персональных данных</b>&nbsp;&mdash; юридическое лицо (<b>ООО &laquo;ФАУНДАРИУМ&raquo;</b>), которое определяет цель обработки персональных данных, устанавливает состав этих данных и&nbsp;процедуры их&nbsp;обработки, если иное не&nbsp;определено законом.
          </p>

          <p class="vModalPolitics__politics-paragraph">
            <b>Персональные данные</b>&nbsp;&mdash; сведения или совокупность сведений о&nbsp;физическом лице, которое идентифицировано или может быть конкретно идентифицировано.
          </p>

          <p class="vModalPolitics__politics-paragraph">
            <b>Неперсональные Данные</b>&nbsp;&mdash; информация и&nbsp;сведения, которые сами по&nbsp;себе не&nbsp;позволяют непосредственно идентифицировать конкретное лицо.
          </p>

          <p class="vModalPolitics__politics-paragraph">
            <b>Информация</b>&nbsp;&mdash; как Персональные, так и&nbsp;не&nbsp;персональные Данные.
          </p>

          <p class="vModalPolitics__politics-paragraph">
            <b>Пользователь</b>&nbsp;&mdash; физическое лицо, получающее доступ к&nbsp;Сайту и\или Облачному сервису Tapper, пользующееся ими и/или осуществляющее заказ соответствующих услуг.
          </p>

          <p class="vModalPolitics__politics-paragraph">
            <b>Обработка</b>&nbsp;&mdash; любые действия с&nbsp;персональными данными в&nbsp;том числе, но&nbsp;не&nbsp;исключительно: доступ, предоставление, распространение, сбор, систематизация, хранение, накопление, запись, передача, блокирование, удаление, уточнение, обновление и/или изменение, обезличивание и&nbsp;другие способы использования персональных данных, осуществляемые Компанией.
          </p>

          <p class="vModalPolitics__politics-paragraph">
            <b>Закон</b>&nbsp;&mdash; Федеральный закон от&nbsp;27.07.2006 &#8470;&nbsp;152-ФЗ (ред.&nbsp;от&nbsp;14.07.2022) &laquo;О&nbsp;персональных данных&raquo;.
          </p>

          <p class="vModalPolitics__politics-paragraph">
            <b>Облачный сервис Tapper/ Сервис</b>&nbsp;&mdash; принадлежащая <b>ООО &laquo;ФАУНДАРИУМ&raquo;</b> система и&nbsp;способ облачного управления заказами (продажа товаров и&nbsp;услуг, а&nbsp;также иной продукции) в&nbsp;заведениях общественного питания через программно-аппаратный комплекс (заявка на&nbsp;регистрацию изобретения в&nbsp;Федеральной службе по&nbsp;интеллектуальной собственности Российской Федерации (Роспатенте) &#8470;&nbsp;2022115517 от&nbsp;08.06.2022), характеризующаяся использованием индивидуального мобильного устройства пользователя (посетителя, гостя, покупателя) при сканировании QR-кода, ссылка которого открывает на&nbsp;устройстве пользователя, предоставляя пользователю следующий функционал:
          </p>

          <ul class="vModalPolitics__list vModalPolitics__politics-block">
            <li>посмотреть меню заведения общественного питания;</li>
            <li>составить дополнительный заказ;</li>
            <li>произвести дистанционную оплату заказа с&nbsp;использованием функции безналичных платежей;</li>
            <li>разделить оплату заказа между несколькими пользователями без ограничения по&nbsp;их&nbsp;количеству;</li>
            <li>произвести отправку ссылки на&nbsp;web-интерфейс контакту пользователя по&nbsp;средствам коммуникации мобильного устройства для произведения оплаты заказа;</li>
            <li>вызвать ответственного сотрудника заведения общественного питания;</li>
            <li>оставить отзыв о&nbsp;работе и&nbsp;качестве обслуживания в&nbsp;заведении общественного питания;</li>
            <li>оставить дополнительное вознаграждение для сотрудника предприятия;</li>
            <li>произвести оплату заказа и&nbsp;чаевых одним платежом пользователя с&nbsp;последующим выделением сервисом чаевых официанта и&nbsp;зачислением их&nbsp;на&nbsp;его персональный банковский счет.</li>
          </ul>

          <p>Сервис Tapper включает в&nbsp;состав программно-аппаратного комплекса в&nbsp;том числе облачный сервис <b>Tapper.Еда</b>, предоставляющий пользователю следующий функционал:</p>

          <ul class="vModalPolitics__list vModalPolitics__politics-block">
            <li>доставка заказанных блюд/ еды из&nbsp;меню заведений общественного питания;</li>
            <li>дистанционное бронирование стола в&nbsp;заведениях общественного питания;</li>
            <li>дистанционный предзаказ блюд/ еды по&nbsp;меню в&nbsp;заведениях общественного питания к&nbsp;определенному времени.</li>
          </ul>

          <p class="vModalPolitics__politics-block">
            Договор об&nbsp;оказании услуг и/или Договор-оферта об&nbsp;оказании услуг&nbsp;&mdash; договор, заключаемый между Оператором персональных данных и&nbsp;Пользователем/ Субъектом персональных данных посредством принятия оферты, расположенной на&nbsp;Сайте, в&nbsp;целях использования Облачного сервиса Таппер в&nbsp;соответствии с&nbsp;предоставляемым функционалом и&nbsp;возможностями данного сервиса, основанными на&nbsp;современных информационных технологиях.
          </p>

          <ul class="vModalPolitics__politics-block vModalPolitics__orderedList">
            <li class="vModalPolitics__orderedItem">
              <b>Общие положения, стороны, цель документа</b>

              <ul class="vModalPolitics__politics-block vModalPolitics__subOrderedList">
                <li class="vModalPolitics__subOrderedItem">
                  Настоящая Политика конфиденциальности и&nbsp;обработки персональных данных (далее&nbsp;&mdash; <b>&laquo;Политика&raquo;</b>) определяет порядок и&nbsp;условия обработки <b>ООО &laquo;ФАУНДАРИУМ&raquo;</b>, действующим по&nbsp;законодательству России (далее&nbsp;&mdash; <b>&laquo;Оператор&raquo;</b> либо <b>&laquo;Мы&raquo;</b>), информации о&nbsp;физическом лице, которая может быть получена Оператором от&nbsp;этого физического лица либо от&nbsp;его законного представителя (далее&nbsp;&mdash; <b>&laquo;Пользователь&raquo;</b>, <b>&laquo;Субъект персональных данных&raquo;</b> либо <b>&laquo;Вы&raquo;</b>), при возникновении следующих отношений с&nbsp;Субъектом персональных данных:

                  <ol class="vModalPolitics__alphaOrderedList">
                    <li>
                      при использовании функций сайтов
                      <a
                        href="https://tapper.cloud"
                        target="_blank"
                        rel="noopener noreferrer"
                      >https://tapper.cloud</a> и/или
                      <a
                        href="https://tapper.ru"
                        target="_blank"
                        rel="noopener noreferrer"
                      >https://tapper.ru</a> , включая все его домены, поддомены и&nbsp;страницы, их&nbsp;содержимое, а&nbsp;также интернет-сервисы и&nbsp;программное обеспечение, предлагаемые Оператором к&nbsp;использованию на&nbsp;этом сайте (далее вместе&nbsp;&mdash; <b>&laquo;Сайт&raquo;</b> и/или <b>&laquo;Сайты&raquo;)</b>;
                    </li>
                    <li>при осуществлении Оператором прав и&nbsp;обязанностей, установленных соглашениями/договорами, заключенными между Оператором и&nbsp;Пользователем;</li>
                    <li>
                      <p class="vModalPolitics__politics-paragraph">
                        при обработке обращений, жалоб, запросов, сообщений, направляемых Оператором и&nbsp;Пользователем друг другу.
                      </p>

                      <div>
                        Оператором персональных данных является
                        <address>
                          Общество с&nbsp;ограниченной ответственностью &laquo;ФАУНДАРИУМ&raquo;, ОГРН: 1217700404206 зарегистрированное по&nbsp;адресу: Российская Федерация, 125212, г. Москва, Головинское шоссе, д.&nbsp;5, корп./ст.&nbsp;1, кв./оф.&nbsp;2136
                        </address>
                      </div>
                    </li>
                  </ol>
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  Целью и&nbsp;назначением Политики является обеспечение надлежащего правового режима персональных данных. Политика не&nbsp;может содержать положения, ограничивающие права и&nbsp;свободы субъекта персональных данных, устанавливающие случаи обработки персональных данных несовершеннолетних, если иное не&nbsp;предусмотрено законодательством Российской Федерации, а&nbsp;также положения, допускающие в&nbsp;качестве условия заключения договора/выражения согласия бездействие субъекта персональных данных.
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  Обработка Персональных данных происходит в&nbsp;соответствии с&nbsp;Федеральным законом &#8470;&nbsp;152-ФЗ, от&nbsp;27.07.2006, исключительно для выполнения Вашего заказа, заявки, поручения и&nbsp;предоставления необходимой информации о&nbsp;состоянии его выполнения, регистрации учетной записи&nbsp;/ аккаунта, участия в&nbsp;рекламных активностях компании, при обращении к&nbsp;клиентской поддержке, а&nbsp;также когда&nbsp;Вы подписываетесь на&nbsp;наши рассылки, участвуете в&nbsp;опросах или оставляете отзывы о&nbsp;наших услугах. Информация о&nbsp;Вас обрабатывается с&nbsp;момента Вашего входа на&nbsp;сайт и&nbsp;начала пользования Сайтом, а&nbsp;также с&nbsp;момента получения Компанией Ваших персональных данных, направленных по&nbsp;электронной почте, через Сайт или предоставленных через другие средства коммуникаций.
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  Положения настоящей Политики Конфиденциальности и обработки персональных данных применяются только к Сайтам и\или Облачному сервису Tapper. Оператор персональных данных не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылками, доступным на Сайтах и/или в Облачном сервисе Tapper.
                </li>
              </ul>
            </li>
            <li class="vModalPolitics__orderedItem">
              <b>Правовое основание обработки персональных данных</b>

              <ul class="vModalPolitics__politics-block vModalPolitics__subOrderedList">
                <li class="vModalPolitics__subOrderedItem">
                  Правовыми основаниями обработки персональных данных являются:

                  <ol class="vModalPolitics__alphaOrderedList">
                    <li>согласие на&nbsp;обработку персональных данных, выраженное способом, установленным законом и&nbsp;настоящей Политикой;</li>
                    <li>соглашения, заключаемые между Оператором и&nbsp;Пользователем;</li>
                    <li>локальные нормативные акты Оператора в&nbsp;области персональных данных.</li>
                  </ol>
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  Субъект персональных данных принимает решение о&nbsp;предоставлении его персональных данных и&nbsp;дает согласие на&nbsp;их&nbsp;обработку свободно, своей волей и&nbsp;в&nbsp;своем интересе. Бездействие субъекта персональных данных не&nbsp;может пониматься как согласие. Согласие на&nbsp;обработку персональных данных должно быть конкретным, предметным, информированным, сознательным и&nbsp;однозначным. Согласие с&nbsp;условиями Политики может быть выражено субъектом персональных данных через совершение любого из&nbsp;следующих действий:

                  <ol class="vModalPolitics__alphaOrderedList">
                    <li>
                      заключение с&nbsp;Оператором договора, такого как, например, Договор-оферта об&nbsp;оказании услуг; при условии, что Пользователю в&nbsp;каждом месте сбора персональных данных предоставлена возможность ознакомиться с&nbsp;полным текстом настоящей Политики;

                      <div>либо</div>
                    </li>
                    <li>простановка символа в&nbsp;чек-боксе (в&nbsp;поле для ввода) на&nbsp;Сайте рядом с&nbsp;текстом вида: &laquo;Я&nbsp;даю согласие на&nbsp;обработку персональных данных на&nbsp;условиях Политики конфиденциальности&raquo;, при условии, что Пользователю в&nbsp;каждом месте сбора персональных данных предоставлена возможность ознакомиться с&nbsp;полным текстом настоящей Политики.</li>
                  </ol>
                </li>
              </ul>
            </li>
            <li class="vModalPolitics__orderedItem">
              <b>Правила обработки персональных данных</b>

              <p>Оператор персональных данных собирает и&nbsp;использует только те&nbsp;персональные данные Пользователей, которые необходимы для выполнения их&nbsp;заказа услуги, и/или осуществления регистрации на&nbsp;Сайте и\или в&nbsp;Облачном сервисе Tapper, с&nbsp;целью создания учетной записи&nbsp;/ аккаунта. Для Пользователя&nbsp;&mdash; это имя, фамилия, адрес электронной почты, если иное не&nbsp;оговорено в&nbsp;условиях данного раздела. Другая информация может быть предоставлена ​​пользователем исключительно на&nbsp;его усмотрение (кроме случаев, если такая информация требуется для обеспечения возможности выполнения договора или ее&nbsp;предоставление предусмотрено законодательством).</p>
              <p>Мы&nbsp;не&nbsp;собираем и&nbsp;просим Вас не&nbsp;предоставлять нам любую Информацию, по&nbsp;обработке которой Законом установлены определенные ограничения и&nbsp;требования, а&nbsp;именно&nbsp;&mdash; информацию о&nbsp;расовом или этническом происхождении, о&nbsp;политических, религиозных или мировоззренческих убеждениях, о&nbsp;членстве в&nbsp;политических партиях и&nbsp;профессиональных союзах, судимостей по&nbsp;уголовным делам или о&nbsp;подозрении в&nbsp;совершении преступлений по&nbsp;уголовным делам, а&nbsp;также данные, касающиеся здоровья, половой жизни, биометрических и&nbsp;генетических данных.</p>

              <ul class="vModalPolitics__politics-block vModalPolitics__subOrderedList">
                <li class="vModalPolitics__subOrderedItem">
                  <b>Цель обработки персональных данных, категории и&nbsp;перечень обрабатываемых персональных данных, категории субъектов, персональные данные которых обрабатываются, способы и&nbsp;сроки обработки и&nbsp;хранения, порядок уничтожения персональных данных при достижении целей их&nbsp;обработки или при наступлении иных законных оснований</b>

                  <ul class="vModalPolitics__thirdOrderedList">
                    <li class="vModalPolitics__thirdOrderedItem">
                      <b>Цель: аутентификация субъекта персональных данных для заключения Договора оказания услуг и/или Договора-оферты об&nbsp;оказании услуг (оформление заказа, заявки, поручения).</b>

                      <p class="vModalPolitics__politics-paragraph">
                        Категории и&nbsp;перечень обрабатываемых данных: фамилия, имя, телефон, электронная почта.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Категории субъектов, персональные данные которых обрабатываются: субъекты персональных данных&nbsp;&mdash; Пользователи Сайта и/или Облачного сервиса Tapper.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, передача (доступ, предоставление), блокирование, удаление, уничтожение персональных данных.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Срок обработки и&nbsp;хранения: до&nbsp;получения от&nbsp;субъекта персональных данных требования о&nbsp;прекращении обработки/отзыва согласия либо&nbsp;10 (десять) лет.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Порядок уничтожения персональных данных при достижении цели их&nbsp;обработки или при наступлении иных законных оснований: лицо, ответственное за&nbsp;обработку персональных данных, производит стирание данных методом перезаписи (замена всех единиц хранения информации на&nbsp;&laquo;0&raquo;) с&nbsp;составлением акта об&nbsp;уничтожении персональных данных.
                      </p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      <b>Цель: <i>связь с&nbsp;Пользователем, направление Пользователю сообщений, уведомлений, запросов, ответов, документов, сообщений рекламного или информационного характера.</i></b>

                      <p class="vModalPolitics__politics-paragraph">
                        Категории и&nbsp;перечень обрабатываемых данных: имя, фамилия, телефон, электронная почта.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Категории субъектов, персональные данные которых обрабатываются: субъекты персональных данных&nbsp;&mdash; Пользователи Сайта и/или Облачного сервиса Tapper..
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение персональных данных.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Срок обработки и&nbsp;хранения: до&nbsp;получения от&nbsp;субъекта персональных данных требования о&nbsp;прекращении обработки/отзыва согласия либо&nbsp;10 (десять) лет.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Порядок уничтожения персональных данных при достижении цели их&nbsp;обработки или при наступлении иных законных оснований: лицо, ответственное за&nbsp;обработку персональных данных, производит стирание данных методом перезаписи (замена всех единиц хранения информации на&nbsp;&laquo;0&raquo;) с&nbsp;составлением акта об&nbsp;уничтожении персональных данных.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        <i><b>Внимание!</b> Вы&nbsp;можете отказаться от&nbsp;получения наших маркетинговых электронных рассылок (как полностью, так и&nbsp;частично), нажав на&nbsp;ссылку <b>&laquo;отменить подписку&raquo;</b> в&nbsp;электронных письмах, которые&nbsp;Вы получаете от&nbsp;нас. Кроме того, для того, чтобы отменить подписку, Вы&nbsp;можете в&nbsp;любой момент связаться с&nbsp;нами отправив нам сообщение на&nbsp;адрес электронной почты, указанный в&nbsp;настоящей Политике конфиденциальности и&nbsp;обработки персональных данных.</i>
                      </p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      <b>Цель: <i>обработка обращений, жалоб, запросов, сообщений, направляемых Оператором и&nbsp;Пользователем друг другу.</i></b>

                      <p class="vModalPolitics__politics-paragraph">
                        Категории и&nbsp;перечень обрабатываемых данных: фамилия, имя, телефон, электронная почта, текст сообщения (если текст сообщения содержит персональные данные).
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Категории субъектов, персональные данные которых обрабатываются: субъекты персональных данных&nbsp;&mdash; Пользователи Сайта и/или Облачного сервиса Tapper.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, передача (доступ, предоставление) блокирование, удаление, уничтожение персональных данных.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Срок обработки и&nbsp;хранения: до&nbsp;получения от&nbsp;субъекта персональных данных требования о&nbsp;прекращении обработки/отзыва согласия либо&nbsp;10 (десять) лет.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Порядок уничтожения персональных данных при достижении цели их&nbsp;обработки или при наступлении иных законных оснований: лицо, ответственное за&nbsp;обработку персональных данных, производит стирание данных методом перезаписи (замена всех единиц хранения информации на&nbsp;&laquo;0&raquo;) с&nbsp;составлением акта об&nbsp;уничтожении персональных данных.
                      </p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      <b>Цель: <i>создание личного кабинета/аккаунта Пользователя на&nbsp;Сайте и/или в&nbsp;Облачном сервисе Tapper.</i></b>

                      <p class="vModalPolitics__politics-paragraph">
                        Категории и&nbsp;перечень обрабатываемых данных: фамилия, имя, отчество, телефон, электронная почта.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Категории субъектов, персональные данные которых обрабатываются: субъекты персональных данных&nbsp;&mdash; Пользователи Сайта и/или Облачного сервиса Tapper..
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, передача (доступ, предоставление) блокирование, удаление, уничтожение персональных данных.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Срок обработки и&nbsp;хранения: до&nbsp;получения от&nbsp;субъекта персональных данных требования о&nbsp;прекращении обработки/отзыва согласия.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Порядок уничтожения персональных данных при достижении цели их&nbsp;обработки или при наступлении иных законных оснований: лицо, ответственное за&nbsp;обработку персональных данных, производит стирание данных методом перезаписи (замена всех единиц хранения информации на&nbsp;&laquo;0&raquo;) с&nbsp;составлением акта об&nbsp;уничтожении персональных данных.
                      </p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      <b>Цель: <i>оставление Пользователем отзывов об&nbsp;услугах Оператора.</i></b>

                      <p class="vModalPolitics__politics-paragraph">
                        Категории и&nbsp;перечень обрабатываемых данных: фамилия, имя, текст сообщения (если текст сообщения содержит персональные данные), данные аккаунтов социальных сетей Пользователя, данные об&nbsp;образе: фотографии, видеозаписи, иная техническая фиксация образов лица и&nbsp;тела.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Категории субъектов, персональные данные которых обрабатываются: субъекты персональных данных&nbsp;&mdash; Пользователи Сайта и/или Облачного сервиса Tapper..
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, передача (доступ, предоставление) блокирование, удаление, уничтожение персональных данных.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Срок обработки и&nbsp;хранения: до&nbsp;получения от&nbsp;субъекта персональных данных требования о&nbsp;прекращении обработки/отзыва согласия либо&nbsp;10 (десять) лет.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Порядок уничтожения персональных данных при достижении цели их&nbsp;обработки или при наступлении иных законных оснований: лицо, ответственное за&nbsp;обработку персональных данных, производит стирание данных методом перезаписи (замена всех единиц хранения информации на&nbsp;&laquo;0&raquo;) с&nbsp;составлением акта об&nbsp;уничтожении персональных данных.
                      </p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      <b>Цель: <i>принятие мер по&nbsp;предотвращению возможного наступления ответственности, осуществление расследований и&nbsp;защиты Оператора персональных данных от&nbsp;любых претензий или обвинений третьих лиц, защита Оператора персональных данных от&nbsp;мошенничества, защита безопасности или целостности Сайта и\или Облачного сервиса Tapper, а&nbsp;также защита интересов и&nbsp;прав Оператора персональных данных, Пользователей и/или партнеров.</i></b>

                      <p class="vModalPolitics__politics-paragraph">
                        Категории и&nbsp;перечень обрабатываемых данных: фамилия, имя, телефон, электронная почта.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Категории субъектов, персональные данные которых обрабатываются: субъекты персональных данных&nbsp;&mdash; Пользователи Сайта и/или Облачного сервиса Tapper.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, передача (доступ, предоставление), блокирование, удаление, уничтожение персональных данных.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Срок обработки и&nbsp;хранения: до&nbsp;получения от&nbsp;субъекта персональных данных требования о&nbsp;прекращении обработки/отзыва согласия либо&nbsp;10 (десять) лет.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Порядок уничтожения персональных данных при достижении цели их&nbsp;обработки или при наступлении иных законных оснований: лицо, ответственное за&nbsp;обработку персональных данных, производит стирание данных методом перезаписи (замена всех единиц хранения информации на&nbsp;&laquo;0&raquo;) с&nbsp;составлением акта об&nbsp;уничтожении персональных данных.
                      </p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      <b>Цель: <i>осуществление денежной транзакции с&nbsp;использованием Облачного сервиса Tapper при информационно-техническом взаимодействии с&nbsp;организацией, предоставляющей услуги интернет-эквайринга.</i></b>

                      <p class="vModalPolitics__politics-paragraph">
                        Категории и&nbsp;перечень обрабатываемых данных: фамилия, имя, отчество, данные банковской карты, в&nbsp;том числе наименование банка плательщика, сведения о&nbsp;платежной системе (MasterCard, Visa, Мир) плательщика, типе оплаты (бесконтактный/ ввод реквизитов карты), маске карты (PAN), типе устройства (Android/ Apple), сведения о&nbsp;заведении, в&nbsp;котором совершена транзакция, время и&nbsp;сумма транзакции для целей сбора аналитических данных для совершенствования и&nbsp;доработки Сервиса.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Категории субъектов, персональные данные которых обрабатываются: субъекты персональных данных&nbsp;&mdash; Пользователи Сайта и/или Облачного сервиса Tapper.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, передача (доступ, предоставление) блокирование, удаление, уничтожение персональных данных.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Срок обработки и&nbsp;хранения: до&nbsp;получения от&nbsp;субъекта персональных данных требования о&nbsp;прекращении обработки/отзыва согласия.
                      </p>
                      <p class="vModalPolitics__politics-paragraph">
                        Порядок уничтожения персональных данных при достижении цели их&nbsp;обработки или при наступлении иных законных оснований: лицо, ответственное за&nbsp;обработку персональных данных, производит стирание данных методом перезаписи (замена всех единиц хранения информации на&nbsp;&laquo;0&raquo;) с&nbsp;составлением акта об&nbsp;уничтожении персональных данных.
                      </p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Обработка персональных данных будет ограничиваться достижением этих конкретных, заранее определенных и&nbsp;законных целей. Не&nbsp;допускается обработка персональных данных, несовместимая с&nbsp;целью обработки.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор обрабатывает Персональные данные Пользователя только в&nbsp;случае их&nbsp;отправки Пользователем через формы, расположенные на&nbsp;сайте или в&nbsp;приложении Сервиса.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Обезличенные данные Пользователей (данные о пользовательской сессии - последовательность запросов, сделанных Пользователем во время использования Сервиса), собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на Сайте/ Сайтах и/или в Сервисе, улучшения качества работы Сервиса и его содержания.
                      <p>
                        Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя.
                      </p>
                      <p>
                        К обезличенным данным в том числе относятся данные о пользовательской сессии - последовательность запросов, сделанных Пользователем во время использования Сервиса, получаемые при доступе к Сервису с помощью сервисов интернет-статистики (Яндекс.Метрика, Google Аналитика, Amplitude и др.) для целей сбора аналитических данных для совершенствования и доработки Сервиса.
                      </p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      <b>О&nbsp;технологии куки (cookie):</b>
                      <ul class="vModalPolitics__fourthOrderedList">
                        <li class="vModalPolitics__fourthOrderedItem">
                          Мы&nbsp;используем технологию &laquo;cookie&raquo; для сбора дополнительных данных об&nbsp;использовании Сайта и&nbsp;для улучшения работы Сайта, повышения качества наших услуг. Куки (cookie): &mdash;&nbsp;это фрагменты данных, отправленные сервером Оператора и&nbsp;хранимые на&nbsp;устройстве Субъекта персональных данных, представляющие собой небольшие файлы, которые Сайт, сервисы сетевого программного обеспечения или поставщики услуг, помещают в&nbsp;устройство через веб-браузер (если это разрешено Вами), что позволяет Сайту или поставщикам услуг распознавать Ваш браузер и&nbsp;хранить и&nbsp;запоминать определенную информацию. Содержимое такого файла может как относиться, так и&nbsp;не&nbsp;относиться к&nbsp;персональным данным, в&nbsp;зависимости от&nbsp;того, содержит&nbsp;ли такой файл персональные данные или содержит обезличенные технические данные.
                        </li>
                        <li class="vModalPolitics__fourthOrderedItem">
                          Субъект персональных данных вправе запретить своему оборудованию прием этих данных или ограничить прием этих данных. При отказе от&nbsp;получения таких данных или при ограничении приема данных некоторые функции Сайта могут работать некорректно. Субъект персональных данных обязуется сам настроить свое оборудование таким способом, чтобы оно обеспечивало адекватный его желаниям режим работы и&nbsp;уровень защиты данных куки (cookie), а&nbsp;Оператор не&nbsp;предоставляет технологических и&nbsp;правовых консультаций на&nbsp;темы подобного характера.
                        </li>
                        <li class="vModalPolitics__fourthOrderedItem">
                          С помощью cookie-файлов мы можем обрабатывать в частности, но не ограничиваясь, такую ​​Информацию как:

                          <ul class="vModalPolitics__list vModalPolitics__politics-block">
                            <li>данные об&nbsp;устройстве: аппаратная модель, уникальные идентификаторы устройства, MAC-адрес, IP- адрес, версия операционной системы и&nbsp;настройки устройства;</li>
                            <li>информацию из&nbsp;Log-файлов: время и&nbsp;продолжительность использования сайта, поисковые запросы, информация о&nbsp;местонахождении и&nbsp;любая другая информация, хранящаяся в&nbsp;Cookie-файлах, что позволяет однозначно идентифицировать Ваш браузер или аккаунт;</li>
                            <li>данные об&nbsp;использовании Вами Сайта, которые мы&nbsp;можем обрабатывать, если&nbsp;Вы посещаете или используете сторонние веб-сайты или приложения для связи с&nbsp;нами, о&nbsp;том, как&nbsp;Вы взаимодействуете с&nbsp;контентом, размещенным на&nbsp;Сайте и\или в&nbsp;Облачном сервисе Tapper.</li>
                          </ul>
                        </li>
                        <li class="vModalPolitics__fourthOrderedItem">
                          Мы&nbsp;используем Cookie-файлы в&nbsp;частности для следующих целей:
                          <ul class="vModalPolitics__list vModalPolitics__politics-block">
                            <li>чтобы помочь Вам войти в&nbsp;систему и&nbsp;чтобы улучшить Ваш опыт пользования Сайтом и\или Облачным сервисом Tapper и/&nbsp;или услугами;</li>
                            <li>чтобы лучше понять как&nbsp;Вы взаимодействуете с&nbsp;Сайтом и\или Облачным сервисом Tapper;</li>
                            <li>чтобы отслеживать общее количество пользователей Сайта и\или Облачного сервиса Tapper и&nbsp;маршрутизацию веб-трафика на&nbsp;Сайте и\или в&nbsp;Облачном сервисе Tapper;</li>
                            <li>чтобы совершенствовать Сайт и\или Облачный сервис Tapper и&nbsp;наши услуги;</li>
                            <li>чтобы понять Ваши предпочтения на&nbsp;основе предыдущей или текущей активности на&nbsp;Сайте и\или в&nbsp;Облачном сервисе Tapper, что позволит нам предоставлять Вам услуги высшего качества.</li>
                          </ul>
                        </li>
                        <li class="vModalPolitics__fourthOrderedItem">
                          Мы&nbsp;также можем использовать Информацию, полученную с&nbsp;помощью Cookie-файлов с&nbsp;целью проведение маркетинговых исследований, для анализа характеристик пользователей сайта, оценки эффективности нашей маркетинговой связи и&nbsp;для приспособления к&nbsp;существующим тенденциям, для планирование наших будущих маркетинговых кампаний, бизнес-аналитики, персонализации сервисов и&nbsp;коммуникаций для Вас.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  <b>Порядок и&nbsp;условия обработки персональных данных</b>
                  <ul class="vModalPolitics__thirdOrderedList">
                    <li class="vModalPolitics__thirdOrderedItem">
                      Обработка персональных данных&nbsp;&mdash; любое действие (операция) или совокупность действий (операций), совершаемых с&nbsp;использованием средств автоматизации или без использования таких средств с&nbsp;персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. В&nbsp;настоящей Политике устанавливаются цели обработки персональных данных, категории и&nbsp;перечень обрабатываемых персональных данных, категории субъектов, персональные данные которых обрабатываются, способы и&nbsp;сроки обработки и&nbsp;хранения, порядок уничтожения персональных данных при достижении целей их&nbsp;обработки или при наступлении иных законных оснований для каждой цели обработки.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор может обрабатывать данные указанными способами (операциями) как в&nbsp;информационных системах персональных данных, так и&nbsp;без использования средств автоматизации.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Сбор персональных данных может происходить в&nbsp;устной, письменной и&nbsp;/ или электронной форме, путем запросов или самостоятельного предоставления Вами необходимых сведений и&nbsp;документов. Персональные данные накапливаются и&nbsp;хранятся в&nbsp;письменной и&nbsp;/ или электронной форме.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор будет обрабатывать персональные данные столько времени, сколько это необходимо для достижения конкретной цели обработки.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      В&nbsp;случае отзыва субъектом персональных данных согласия на&nbsp;обработку персональных данных или истечения срока действия согласия, направления субъектом персональных данных требования о&nbsp;прекращении обработки персональных данных, Оператор вправе заблокировать данные и&nbsp;обрабатывать их&nbsp;в&nbsp;архивном виде в&nbsp;течение&nbsp;3 (трёх) лет.
                    </li>
                  </ul>
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  <b>Меры по защите персональных данных</b>
                  <ul class="vModalPolitics__thirdOrderedList">
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор принимает все необходимые меры для защиты персональных данных от&nbsp;несанкционированного, случайного или незаконного уничтожения, потери, изменения, недобросовестного использования, раскрытия или доступа, а&nbsp;также иных незаконных форм обработки.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      По&nbsp;умолчанию персональная информация обрабатывается автоматическим оборудованием без доступа к&nbsp;ней кого-либо. В&nbsp;случае если такой доступ понадобится, то&nbsp;Оператор предоставляет доступ к&nbsp;персональным данным только тем лицам, которым эта информация необходима для обеспечения Цели обработки. Для защиты и&nbsp;обеспечения конфиденциальности данных такие лица должны обязаться соблюдать внутренние правовые правила и&nbsp;процедуры, технические и&nbsp;организационные меры безопасности в&nbsp;отношении обработки персональной информации.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Как при обработке персональных данных без использования средств автоматизации, так и&nbsp;при автоматизированной обработке обеспечивается достаточная безопасность места, где происходит обработка персональных данных.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Раскрытие персональных данных может быть произведено лишь в&nbsp;соответствии с&nbsp;действующим законодательством Российской Федерации по&nbsp;требованию суда, правоохранительных органов, и&nbsp;в&nbsp;иных предусмотренных законодательством Российской Федерации случаях. Мы&nbsp;можем раскрывать Вашу Информацию в&nbsp;ситуациях, которые, по&nbsp;нашему мнению: (1) является чрезвычайными ситуациями,связанными с&nbsp;потенциальной угрозой физической безопасности любого лица или имущества, если мы&nbsp;считаем, что Ваша Информация любым образом связана с&nbsp;такой угрозой; (2) является связанной с&nbsp;незаконным или неподходящим, по&nbsp;нашему мнению, использованием Сайта и/или Облачного сервиса Tapper.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор не&nbsp;проверяет достоверность информации, предоставляемой Субъектом персональных данных, и&nbsp;исходит из&nbsp;того, что Субъект персональных данных в&nbsp;порядке принципа добросовестности и&nbsp;требований&nbsp;ст.&nbsp;19&nbsp;Гражданского кодекса&nbsp;РФ предоставляет достоверную и&nbsp;достаточную информацию, заботится о&nbsp;своевременности внесения изменений в&nbsp;ранее предоставленную информацию, актуализирует информацию.
                    </li>
                  </ul>
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  <b>Передача персональных данных третьим лицам и&nbsp;распространение персональных данных</b>
                  <ul class="vModalPolitics__thirdOrderedList">
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор вправе осуществить передачу (способом доступа и&nbsp;предоставления) персональных данных следующим третьим лицам:

                      <ol class="vModalPolitics__alphaOrderedList">
                        <li>в&nbsp;отношении которых произведена уступка (перевод) прав или обязанностей, либо новация по&nbsp;соответствующему соглашению (например, при правопреемстве, при продаже или ином отчуждении бизнеса в&nbsp;целом или части бизнеса);</li>
                        <li>любому регулирующему органу, правоохранительным органам, центральным или местным органам власти, другим официальным или государственным органам или судам, которым Оператор обязан по&nbsp;запросу предоставлять информацию в&nbsp;соответствии с&nbsp;применимым законодательством;</li>
                        <li>лицам, осуществляющим обеспечение правовой защиты Оператора или третьих лиц при нарушении их&nbsp;прав либо угрозе нарушения их&nbsp;прав, включая нарушение законов или регулирующих документов;</li>
                        <li>организациям, оказывающим информационно-технологические услуги и&nbsp;осуществляющим сбор, учет и&nbsp;передачу информации, необходимой для формирования распоряжений в&nbsp;системе интернет-платежей (интернет-эквайринг), с&nbsp;которыми Оператор сотрудничает в&nbsp;рамках агентских правоотношений, в&nbsp;частности Обществу с&nbsp;ограниченной ответственностью &laquo;Бест2пей&raquo;, Обществу с&nbsp;ограниченной ответственностью &laquo;КЛАУДПЭЙМЕНТС&raquo;.</li>
                        <li>в&nbsp;случае если Субъект персональных данных сам выразил согласие на&nbsp;передачу персональных данных третьему лицу, либо передача персональных данных требуется для исполнения соглашения или договора, заключенного с&nbsp;Субъектом персональных данных. Сюда относятся в&nbsp;том числе случаи, когда Пользователь разрешил своему оборудованию прием, передачу и&nbsp;хранение файлов технологии куки (cookie), если такой файл содержит персональные данные.</li>
                      </ol>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор вправе осуществить передачу (способом распространения неограниченному кругу лиц) персональных данных в&nbsp;следующих случаях:
                      <ol class="vModalPolitics__alphaOrderedList">
                        <li>Пользователь сам разрешил свободное распространение категорий персональных данных неограниченному кругу лиц.</li>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  <b>Права и&nbsp;обязанности в&nbsp;отношении персональных данных.</b>
                  <ul class="vModalPolitics__thirdOrderedList">
                    <li class="vModalPolitics__thirdOrderedItem">
                      Субъект персональных данных обязан заботиться о&nbsp;достоверности предоставленной информации, о&nbsp;своевременности внесения изменений в&nbsp;предоставленную информацию, ее&nbsp;актуализации, в&nbsp;противном случае Оператор не&nbsp;несет ответственности за&nbsp;неисполнение обязательств, любые убытки, вред или потери.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Для реализации права на дополнение, исправление, блокировку и удаление персональных данных, Субъект персональных данных вправе направить  письменного обращение на адрес электронной почты Оператора, указанный в настоящей Политике.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Основные права субъекта персональных данных:
                      <ul class="vModalPolitics__list vModalPolitics__politics-block">
                        <li>запрашивать информацию об&nbsp;осуществляемой обработке персональных данных;</li>
                        <li>отзывать согласие на&nbsp;обработку персональных данных;</li>
                        <li>требовать ограничений на&nbsp;обработку персональных данных;</li>
                        <li>требовать прекратить обработку персональных данных, если это предусмотрено применимым законодательством и&nbsp;настоящей Политикой.</li>
                      </ul>

                      <p>В&nbsp;случаях, предусмотренных применимым законодательством, Субъект персональных данных обладает другими правами, не&nbsp;указанными выше.</p>
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Субъект персональных данных обязуется воспользоваться реквизитами из&nbsp;настоящей Политики для направления запросов о&nbsp;реализации прав субъекта персональных данных или жалоб относительно некорректности информации или незаконности ее&nbsp;обработки. Такие запросы и&nbsp;жалобы рассматриваются в&nbsp;срок, не&nbsp;превышающий&nbsp;10 (Десяти) рабочих дней с&nbsp;даты поступления Оператору.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Субъект персональных данных вправе в&nbsp;любое время отозвать согласие на&nbsp;обработку персональных данных. Для отзыва согласия на&nbsp;обработку персональных данных Субъект персональных данных направляет Оператору Уведомление в&nbsp;письменной форме об&nbsp;отзыве согласия по&nbsp;адресу электронной почты, указанному в&nbsp;разделе реквизитов настоящей Политики.
                    </li>
                  </ul>
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  <b>Правила хранения и&nbsp;обработки персональных данных граждан&nbsp;РФ, правила о&nbsp;трансграничной передаче персональных данных</b>
                  <ul class="vModalPolitics__thirdOrderedList">
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор осуществляет обработку персональных данных граждан Российской Федерации с&nbsp;использованием баз данных, находящихся на&nbsp;территории Российской Федерации.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор до&nbsp;начала осуществления трансграничной передачи персональных данных обязан убедиться в&nbsp;том, что иностранным государством, на&nbsp;территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных и&nbsp;уведомить государственный орган, уполномоченный в&nbsp;сфере персональных данных, о&nbsp;намерении осуществлять трансграничную передачу персональных данных на&nbsp;территории иностранных государств, отвечающих указанным требованиям.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Оператор до&nbsp;начала осуществления трансграничной передачи персональных данных на&nbsp;территорию иностранного государства, в&nbsp;котором не&nbsp;обеспечивается надежная защита прав субъектов персональных данных, обязан получить разрешение государственного органа, уполномоченного в&nbsp;сфере персональных данных, осуществлять трансграничную передачу персональных данных на&nbsp;территории таких иностранных государств.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="vModalPolitics__orderedItem">
              <b>Изменение Политики конфиденциальности. Применимое законодательство. Толкование.</b>
              <ul class="vModalPolitics__politics-block vModalPolitics__subOrderedList">
                <li class="vModalPolitics__subOrderedItem">
                  Оператор имеет право вносить изменения в&nbsp;настоящую Политику. При внесении изменений в&nbsp;актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в&nbsp;силу с&nbsp;момента ее&nbsp;размещения, если иное не&nbsp;предусмотрено новой редакцией Политики. Утратившие силу редакции доступны в&nbsp;архиве по&nbsp;указанному в&nbsp;Политике адресу.
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  Оператор информирует субъектов персональных данных, ранее выразивших свое согласие с&nbsp;Политикой, об&nbsp;изменении Политики, руководствуясь при выборе формы информирования тем, что согласие субъекта на&nbsp;обработку персональных данных должно быть конкретным, предметным, информированным, сознательным и&nbsp;однозначным.
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  Местом выражения согласия и&nbsp;местом исполнения Политики всегда является место нахождения Оператора, а&nbsp;правом, применимым к&nbsp;отношениям Оператора и&nbsp;Субъекта персональных данных, всегда является право России, вне зависимости от&nbsp;того, где находится Субъект персональных данных или оборудование, используемое&nbsp;им. Все споры и&nbsp;разногласия разрешаются по&nbsp;месту нахождения Оператора, если законом не&nbsp;предусмотрено иное.
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  Политика начинает регулировать отношения Субъекта персональных данных и&nbsp;Оператора с&nbsp;момента выражения Субъектом персональных данных согласия с&nbsp;ее&nbsp;условиями и&nbsp;действует бессрочно. Бессрочность действия Политики как документа никаким образом не&nbsp;означает бессрочность/отсутствие ограничений срока обработки персональных данных. Одностороннее прекращение действия Политики по&nbsp;воле одной из&nbsp;сторон не&nbsp;допускается.
                </li>
                <li class="vModalPolitics__subOrderedItem">
                  <b>Правила толкования:</b>
                  <ul class="vModalPolitics__thirdOrderedList">
                    <li class="vModalPolitics__thirdOrderedItem">
                      Термины &laquo;соглашение&raquo; и&nbsp;&laquo;договор&raquo; равнозначны.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Считается, что за&nbsp;словами &laquo;включают&raquo;, &laquo;включает&raquo;, &laquo;включая&raquo;, &laquo;например&raquo;, &laquo;к&nbsp;примеру&raquo;, &laquo;в&nbsp;том числе&raquo;, &laquo;такие как&raquo; всегда следует словосочетание &laquo;но&nbsp;не&nbsp;ограничиваясь&raquo;, которое не&nbsp;ограничивает общего характера того, что предшествует этим словам.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Считается, что слова &quot;или&quot;/&quot;либо&quot; понимаются по&nbsp;умолчанию как перечисление, то&nbsp;есть аналогично &laquo;и&raquo;, если из&nbsp;смысла текста прямо не&nbsp;следует, что слово &quot;или&quot;/&quot;либо&quot; обозначает именно выбор одного из&nbsp;вариантов.
                    </li>
                    <li class="vModalPolitics__thirdOrderedItem">
                      Считается, что значение Слова, использованного с&nbsp;Заглавной буквы, ничем не&nbsp;отличается от&nbsp;значения этого&nbsp;же слова, использованного со&nbsp;строчной буквы.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="vModalPolitics__orderedItem">
              <b>Реквизиты Оператора</b>
              <div>
                <p><b>Общество с&nbsp;ограниченной ответственностью &laquo;ФАУНДАРИУМ&raquo;,</b></p>
                <p>ОГРН: 1217700404206, ИНН: 7743365653</p>
                <p>КПП: 774301001, ОКПО: 71086092</p>
                <p><b>Юридический (почтовый) адрес:</b> 125212, г. Москва, Головинское шоссе, д.&nbsp;5, корп./ст.&nbsp;1, кв./оф.&nbsp;2136</p>
                <p><b>Фактический адрес (местонахождение):</b> г. Москва, Пресненская набережная, д.12, башня &laquo;Федерация Восток&raquo;, 37&nbsp;этаж</p>
                <p>Email: <a href="mailto:info@foundarium.com">info@foundarium.com</a></p>
              </div>
              <ul class="vModalPolitics__politics-block vModalPolitics__subOrderedList">
                <li class="vModalPolitics__subOrderedItem">
                  <b>Информация для реализации прав субъекта персональных данных: </b>
                  Субъект персональных данных может реализовать все права, присущие субъекту персональных данных, а&nbsp;также получить разъяснения по&nbsp;вопросам, касающимся обработки персональных данных, обратившись к&nbsp;Оператору по&nbsp;адресу электронной почты Оператора.
                </li>
              </ul>
            </li>
            <li class="vModalPolitics__orderedItem">
              <b>Сведения о документе</b>
              <ul class="vModalPolitics__politics-block vModalPolitics__subOrderedList">
                <li class="vModalPolitics__subOrderedItem">
                  Дата публикации настоящей редакции документа:
                  <b>
                    <i>14 февраля 2023 года</i>
                  </b>
                </li>
              </ul>
            </li>
          </ul> -->
        </div>
      </div>

      <div class="vModalPolitics__buttons">
        <div class="vModalPolitics__cancelBtnWrapper">
          <button
            class="vModalPolitics__cancelBtn"
            @click="cancelPolitics()"
          >
            Не соглашаться
          </button>
        </div>
        <VButton
          :fix-width="true"
          @click="confirmDocument"
        >
          Согласиться
        </VButton>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/v-button'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'VModalPolitics',

  components: {
    VButton,
  },

  props: {
    legalDocument: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  emits: ['togglePoliticsModal', 'confirmDocument', 'logoutFunc'],

  methods: {
    ...mapActions('user', ['LOGOUT']),
    ...mapActions('legalDocuments', ['TOGGLE_POLITICS_ERROR']),

    async cancelPolitics() {
      this.TOGGLE_POLITICS_ERROR(true)
      this.$emit('togglePoliticsModal')
      this.$emit('logoutFunc')
    },

    confirmDocument() {
      this.$emit('confirmDocument', this.legalDocument.code)
    },
  },
}
</script>

<style lang="scss">
.vModalPolitics {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dcdee3;
    opacity: 0.9;
    z-index: 1;
  }
  &__content {
    max-width: 652px;
    padding: 32px;
    border: 1px solid #e6e7eb;
    border-radius: 25px;
    background: #fff;
    z-index: 2;
  }
  &__title {
    max-width: 439px;
    margin: 0 auto;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  &__politicsContainer {
    margin-bottom: 22px;
    padding: 8px 12px;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    overflow: hidden;
  }
  &__politics {
    max-height: 256px;
    overflow-y: scroll;
    scrollbar-color: #6764ff;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #6764ff;
      border-radius: 2px;
    }
  }
  &__politics-block {
    margin-bottom: 12px;
  }
  &__politics-paragraph {
    margin-bottom: 8px;
  }
  &__list {
    margin-left: 12px;
    list-style-type: disc;
    list-style-position: inside;
  }
  &__orderedList {
    counter-reset: list1;
  }
  &__orderedItem::before {
    counter-increment: list1;
    content: counter(list1) '. ';
    font-weight: 700;
  }
  &__subOrderedList {
    margin-left: 12px;
    margin-top: 12px;
    counter-reset: list2;
  }
  &__subOrderedItem {
    margin-bottom: 8px;
  }
  &__subOrderedItem::before {
    counter-increment: list2;
    content: counter(list1) '.' counter(list2) '. ';
    font-weight: 700;
  }
  &__alphaOrderedList {
    margin-top: 12px;
    margin-bottom: 12px;
    list-style-type: lower-alpha;
  }
  &__thirdOrderedList {
    margin-left: 12px;
    margin-top: 12px;
    counter-reset: list3;
  }
  &__thirdOrderedItem {
    margin-bottom: 8px;
  }
  &__thirdOrderedItem::before {
    counter-increment: list3;
    content: counter(list1) '.' counter(list2) '.' counter(list3) '. ';
    font-weight: 700;
  }
  &__fourthOrderedList {
    margin-left: 12px;
    margin-top: 12px;
    counter-reset: list4;
  }
  &__fourthOrderedItem::before {
    counter-increment: list4;
    content: counter(list1) '.' counter(list2) '.' counter(list3) '.'
      counter(list4) '. ';
    font-weight: 700;
  }
  &__buttons {
    display: flex;
  }
  &__cancelBtnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-right: 16px;
  }
  &__cancelBtn {
    border: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #80869a;
    background-color: transparent;
    cursor: pointer;
  }
}
.vButton.fixWidth {
  max-width: 286px;
  margin: 0;
}
</style>
